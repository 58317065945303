import axios from "axios";
import shajs from "sha.js";
import { REFRESH_TOKEN, ACCESS_TOKEN, AUTH_KEY } from "./authProvider";
import { GET_ONE, UPDATE } from "react-admin";

export const client = axios.create({
  baseURL: '/api/dashboard',
  timeout: 300000,
  // headers: {'X-Custom-Header': 'foobar'}
});

const accessToken = sessionStorage.getItem(ACCESS_TOKEN);
if (accessToken) {
  client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}


client.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const { config } = error.response;
    if (error.response.status === 419 && !config._retry) {
      const token = sessionStorage.getItem(REFRESH_TOKEN);
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
      const response = await client.get("managers/refresh-token");
      const { data, accessToken, refreshToken } = response.data;
      sessionStorage.setItem(AUTH_KEY, JSON.stringify(data));
      sessionStorage.setItem(ACCESS_TOKEN, accessToken);
      sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
      client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      config.headers.Authorization = `Bearer ${accessToken}`;
      config._retry = true;
      return client(config);
    }
    return Promise.reject(error);
  }
);

export async function dataProvider(action, collection, request) {
  console.log(arguments);
  if (request?.data) {
    const password = request.data.password;
    delete request.data.password;
    delete request.data.password1;
    delete request.data.autofilled_password;
    if (password) {
      request.data.passwordEncoded = shajs("sha512")
        .update(password)
        .digest("base64");
    }
  }
  if (action === "LOGIN") {
    const response = await client.get("managers/get-ticket");
    const { jwt } = response.data;
    client.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  } else {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN);
    client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  const url = `${collection}/${action.toLowerCase().replace(/_/g, "-")}`;
  if (collection === "profile" && action !== GET_ONE && action !== UPDATE) {
    return;
  }
  if (request?.data?.buttonStatus === "publish"){
    request.data.buttonStatus = undefined;
    request.data.status = 2;
  }
  if (request?.data?.buttonStatus === "save as draft"){
    request.data.buttonStatus = undefined;
    request.data.status = 0;
  }
  if (request?.data?.buttonStatus === "confirm"){
    request.data.buttonStatus = undefined;
    request.data.status = 1;
  }
  if (request?.filter?.q){
    request.filter = {q: request.filter.q};
    request.sort = {};
  }
  return client
    .post(url, request)
    .then(({ data }) => {
      if (collection === "profile" && action === UPDATE) {
        sessionStorage.setItem(AUTH_KEY, JSON.stringify(data?.data));
      }
      return data;
    })
    .catch((error) => {
      console.log(error.response.data)
      throw { ...error?.response?.data };
    });
}
